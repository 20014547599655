import React from "react";
import { Link } from "gatsby";
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import TagsComponent from "./tagsComponent";

const ArticleGridItem = (props) => {
  const article = props.article;

  return (
    <Link to={"/" + article.slug + "/"} style={{ textDecoration: "none" }}>
      <ArticleContainer>
        <div>
          <TagsComponent
            tags={article.frontmatter.tags}
            shouldLink="false"
          ></TagsComponent>
          <Box height="8px"></Box>
          <ArticleTitle>{article.frontmatter.title}</ArticleTitle>
          <ArticleSubtitle>{article.frontmatter.subtitle}</ArticleSubtitle>
        </div>
        <ArticleDate>
          {(article.frontmatter.dateUpdated || article.frontmatter.date) +
            " ·  " +
            article.frontmatter.readingTime +
            " min read"}
        </ArticleDate>
      </ArticleContainer>
    </Link>
  );
};

export default ArticleGridItem;

const ArticleTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "18px",
  lineHeight: "1.3",
  marginBottom: "8px",
  color: theme.palette.text.primary,
  [theme.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
}));

const ArticleSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "1.2",
  marginBottom: "8px",
  color: theme.palette.text.primary,
  [theme.breakpoints.down("xs")]: {
    fontSize: "15px",
  },
}));

const ArticleDate = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.text.disabled,
}));

const ArticleContainer = styled("div")(({ theme }) => ({
  background: theme.palette.articleGridBackground,
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingBottom: "18px",
  paddingTop: "18px",
  height: "100%",
  minHeight: "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("xs")]: {
    padding: "8px",
  },
}));
